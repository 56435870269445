<template>
  <b-card class="text-center">
    <h3 class="mb-4">{{ $t('reset_password') }}</h3>
      <b-row>
        <b-col sm="4" offset="4">
          <form @submit.prevent="reset" @keydown="form.onKeydown($event)" class="reset-form">
            <div class="form-group">
                <label for="exampleInputPassword1" class="text-uppercase">{{ $t('password') }}</label>
                <input type="password" class="form-control" v-model="form.password" :class="{ 'is-invalid': form.errors.has('password') }">
                <has-error :form="form" field="password" />
            </div>
            <div class="form-group">
                <label for="exampleInputPassword2" class="text-uppercase">{{ $t('password_confirmation') }}</label>
                <input type="password" class="form-control" v-model="form.password_confirmation" :class="{ 'is-invalid': form.errors.has('password_confirmation') }">
                <has-error :form="form" field="password_confirmation" />
            </div>

            <div class="form-group">
              <button :disabled="form.busy" type="submit" class="btn btn-danger">{{ $t('reset') }}</button>
            </div>
            <router-link :to="{ name: 'login' }" class="small">{{ $t('login') }}</router-link>
            <br>
            <router-link :to="{ name: 'register' }" class="small">{{ $t('register') }}</router-link>
          </form>
        </b-col>
      </b-row>
  </b-card>
</template>
<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import Form from 'vform'
export default {
  name: 'reset',
  layout: 'messages',
  metaInfo () {
    return { title: this.$t('reset_password') }
  },
  data: () => ({
    form: new Form({
      password: '',
      password_confirmation: ''
    })
  }),
  methods: {
    async reset () {
      this.$root.$refs.loading.start()
      const apiUrl = process.env.VUE_APP_ENDPOINT_V1
      const email = this.$route.query.email
      const token = this.$route.params.token
      if (typeof token !== 'undefined' && typeof email !== 'undefined') {
        this.form.email = email
        this.form.token = token
        await this.form.post(apiUrl + 'password/reset').then(response => {
          if (response.data.status === 'success') {
            Swal.fire({
              icon: 'info',
              title: 'Інфо!',
              text: response.data.msg,
              reverseButtons: true,
              confirmButtonText: this.$t('ok'),
              cancelButtonText: this.$t('cancel')
            })
            setTimeout(() => {
              this.$router.push({ name: 'login' })
            }, 1000)
          }
        }).catch(error => {
          this.form.errors.set(error.response.data.error.errors)
        })
      }
      this.$root.$refs.loading.finish()
    }
  }
}
</script>
